@use "../variables" as v;

#detail-container{
  position: relative;
  background-image: url("../assets/background-home.png");
  background-size: cover;
  z-index: 4;
  display: flex;
  align-items: center;
  flex-direction: column;


  #light-container{
    position: absolute;
    top:-30px;
  }

  .home-title{
    display: flex;
    margin-top: 85px;
    font-size: 22px;
    font-weight: 500;
    align-items: center;
    color: v.$theme-gold;
  }

  .nft-container{
    color: v.$theme-gold;

    .img-container{
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: url("../assets/frame-active.png");
      background-size: cover;
      width: 330px;
      height: 330px;
      img{
        border-radius: 12px;
        width: 65%;
        height: 65%;
      }
    }

  }

  .info-container{
    background: linear-gradient(115.45deg, rgba(160,29,29,0.5) 0%, rgba(130, 194, 255, 0) 101.95%);
    border-radius: 8px;
    color: white;
    padding: 14px 16px 16px 14px;
    //opacity: 0.5;
    width: 313px;
    margin-bottom: 12px;
    border: 1px solid rgba(255,255,255,0.2);



    .detail-container{
      display: flex;
      flex-direction: column;
      opacity: 1;

      .detail-pair{
        display: flex;
        justify-content: space-between;
        margin-top: 12px;
        .detail-key{
          color: #BCBCBC;
        }

        .detail-value{
          color: white;
        }
      }

    }


  }

  .generate-poster{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 185px;
    height: 39px;
    background: linear-gradient(90deg, #DF4432 0%, #EE8174 100%);
    border-radius: 35px;
    color: white;
    margin: 24px 0 27px 0;

    a{
      text-decoration: none;
      color: white;
    }
  }


}