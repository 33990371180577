.swiper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-wrapper{
  display: flex;
  align-items: center;
  justify-items: center;
}

.swiper-slide {
  text-align: center;
  //border:1px solid white;
  font-size: 18px;
  width:180px;
  height:180px;
  background: url("../assets/frame.png");
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;

  &.swiper-slide-active {
    color:#fff;
    background: url("../assets/frame-active.png");
    z-index: 2;
    transform: scale(1.4);
    background-size: cover;

  }

}

.swiper-slide img {
  display: block;
  width: 70%;
  height: 70%;
  object-fit: cover;
  border-radius: 8px;
}