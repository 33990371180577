@use "../variables" as v;

#list-container{
  height: 100vh;
  background-image: url("../assets/background-get.png");
  background-size: cover;
  display: flex;
  justify-content: center;

  #content-container{
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    margin-top: 60px;
    height: 520px;
    align-content: flex-start;
    width: 380px;


    .card-wrapper{
      .card-container{
        width: 159px;
        height: 159px;
        margin: 8px 14px;
        background-image: url("../assets/card-frame.png");
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;

        img{
          border-radius: 12px;
        }

        .add-container{
          width: 37px;
          height: 37px;
          img{
            width: 100%;
            height: 100%;
          }
        }
      }

      .nft-name{
        color: v.$theme-gold;
        font-size: 12px;
        font-weight: 400;
      }
    }

  }



  .duty-container {
    position: absolute;
    bottom: 22px;
  }
}